

.container {
  padding: 1rem;
  @include media($md) {
    padding: 2.5rem 2rem;
  }
  //lost-utility: edit;

  &.flush {
    padding: 0;
  }

  &.container-tp {
    padding: 1rem;
    @include media($md) {
      padding: 4rem;
    }
  }
}

.row {
  lost-center: $max-width;
  lost-utility: clearfix;

  &.full {
    max-width: none;
  }
}


@for $i from 1 through 12 {
  .item-#{$i} {
    lost-column: 1/1 1;

    @include media($md) {
      lost-column: 1/$i $i;
    }
  }
}





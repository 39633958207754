.cta2col__container {

  .cta {
    lost-column: 1/1 1;
    margin-bottom: 20px;
    border-radius: 4px;
    background-color: theme(ac-green);
    flex-direction: column;
    justify-content: space-between;
    align-items: initial;

    &:last-child {
      margin-bottom: 0px;
    }

    @include media($md) {
      lost-column: 1/2 2;
      &:nth-last-child(-n+2) {
        margin-bottom: 0px;
      }
    }

    .cta__footer {
      padding: 20px 25px;
      background-color: theme(dark-green);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      .cta__buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    .cta__body {
      display: flex;
      flex-direction: row;
      padding: 40px 20px;

      .cta__icon {
        margin-right: 40px;
        img {
          width: 76px;
        }

      }

      .cta__text {

        .meta__title {
          color: #ffffff;
          @include fluid-type(20px, 20px);
          margin-bottom: 6px;
          font-weight: bold;
        }

        .cta__title {
          color: #ffffff;
          margin-top: 0;
          @include fluid-type(16px, 23px);

        }
      }
    }
  }
}
// Mixins
// --------------------------------------------------


// Colors

@function theme($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }

  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}

@function shadow($key) {
  @if map-has-key($shadows, $key) {
    @return map-get($shadows, $key);
  }

  @warn "Unknown `#{$key}` in $shadows.";
  @return null;
}


// Media queries
@mixin media($width) {
  @media only screen and (min-width: #{$width}) {
    @content;
  }
}

@mixin hidpi {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2.0dppx) {
    @content;
  }
}

// Tint and shade
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

@mixin shadow($shadow) {
  box-shadow: $shadow;
}

// Heading sizes
// Heading sizes
// / @site https://madebymike.com.au/writing/precise-control-responsive-typography/
@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: $sm, $upper-range: $xlg) {

  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));

  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range){
    font-size: $max-font-size;
  }
}


@mixin gap ($type:'padding', $position: 'top', $size: $gap-size, $size-m: $gap-size-m) {
  @if $position == 'top' {
    #{$type}-top: $size;
    @include breakpoint($md) {
      #{$type}-top: $size-m;
    }
  }
  @else if $position == 'bottom' {
    #{$type}-bottom: $size;
    @include breakpoint($md) {
      #{$type}-bottom: $size-m;
    }
  }
  @else {
    #{$type}-top: $size;
    #{$type}-bottom: $size;
    @include breakpoint($md) {
      #{$type}-top: $size-m;
      #{$type}-bottom: $size-m;
    }
  }
}


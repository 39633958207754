// Helpers
// --------------------------------------------------

// Hidden but can still be seen by screen readers
.screen-reader-only {
  position: absolute;
  left: -9999em;
  top: -9999em;
}

// Clearfix
.clearfix {
  zoom: 1;
  overflow: visible !important;

  &:after {
    content: "";
    display: block;
    clear: both;
    height: 0;
  }
}

// Breakpoint indicator (hide this in production)

.breakpoint {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #0ff;
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
  z-index: 9999;

  &:before {
    content: "XS";
    width: 30px;
    display: block;
    text-align: center;

    @include media($sm) {
      content: "SM";
    }

    @include media($md) {
      content: "MD";
    }

    @include media($lg) {
      content: "LG";
    }

    @include media($xlg) {
      content: "XLG";
    }
  }
}
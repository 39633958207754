.page__content {
  height: 100vh;
  width: 100vw;
}

.background__slider {

    position: relative;
  }

.image__container {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment:scroll;
}

.text__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow: scroll;
  .text__content {
      lost-column: 1/1 1;

    @include media(825px) {
      lost-column: 2/3;
      lost-offset: 1/3;

    }

      @include media(1030px) {
        lost-column: 1/2;
        lost-offset: 1/2;

      }

    color: #ffffff;

    h1 {
      color: inherit;
    }



  }

  .company__logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0px;
    width: 200px;

    @include media(750px) {
      margin-bottom: 80px;
      justify-content: flex-end;
      width: auto;
    }

  }
}
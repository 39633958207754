.contacts__container {

  .contacts {
    .contact {
      lost-column: 1/1 1;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }

      @include media($md) {
        lost-column: 1/4 4;
        &:nth-last-child(-n+4) {
          margin-bottom: 0px;
        }
      }

      .contact__details {

        margin-top: 16px;

        & > * {
          margin-top: 0;
          margin-bottom: 8px;
        }

        .contact__name {
          @include fluid-type(20px, 20px);
          font-weight: bold;
        }

        .contact__role,
        .contact__number{
          @include fluid-type(17px, 17px);
          line-height: 1;

        }
        .contact__list {
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          li {
            margin-right: 10px;
            a {
              @include fluid-type(17px, 17px);
              font-weight: 600;
              color: theme(ac-green);

              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}
// Lists
// --------------------------------------------------

// Remove all styles from lists by default
ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

// Add base styles back to some lists
.ul,
.ol,
.wysiwyg ul,
.wysiwyg ol {
  margin-left: 1.5em;
}

// Unordered list
.ul,
.wysiwyg ul {
  margin-bottom: $paragraph-spacing;

  li {
    list-style: disc outside;
  }
}

// Ordered list
.ol,
.wysiwyg ol {
  margin-bottom: $paragraph-spacing;

  li {
    list-style: decimal outside;
  }
}

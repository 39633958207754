// Icons
// --------------------------------------------------

.icon {
  width: 32px;
  height: 32px;
}

.icon--small {
  width: 16px;
  height: 16px;
}

.icon--large {
  width: 64px;
  height: 64px;
}

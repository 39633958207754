// Variables
// --------------------------------------------------
$colors: (
  ac-green: rgba(38, 137, 13, 1.0),
  dark-green: rgba(44, 118, 26, 1.0),
  light-green: rgba(38, 137, 13, 0.2),
  gray: rgba(118, 118, 118, 1.0),
  light-gray: rgba(248, 248, 248, 1.0),

);

$shadows: (
  panel: 0px 2px 12px rgba(0, 0, 0, 0.15)
);


// Colors
$brand-color: theme(ac-green);
$action-color: $brand-color;
$black: #000;
$white: #fff;
$background-color: $white;
$border-color: $action-color;



$max-width: 1186px;
// Grid
$gutter-width: 30px;
// Text colors
$base-text-color: $black;
$heading-text-color: $black;


// Typography
$base-font-family: proxima-nova, "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$heading-font-family: proxima-nova, "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$base-line-height: 1.45;
$heading-line-height: 1.2;

// Border
$base-border-width: 1px;
$base-border: $base-border-width solid theme(gray);
$base-border-radius: 4px;


$base-spacing: 16px;
$half-spacing: 8px;
$paragraph-spacing: 16px;
$double-spacing: $base-spacing * 2;

// Animation
$short-duration: 0.15s;
$base-duration: 0.3s;
$long-duration: 0.5s;

// Easing curves
$ease-in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$ease-out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);

// Breakpoints
$sm: 400px;
$md: 500px;
$lg: 700px;
$xlg: 1050px;

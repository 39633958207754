.success__container {
  background-color: theme(light-gray);

  .factors__container {
    .panel {
      lost-column: 1/1 1;

      @include shadow(shadow(panel));
      border-radius: 6px;
      text-decoration: none;
      color: black;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      transition-duration: 0.5s;

      &:last-child {
        margin-bottom: 0px;
      }

      @include media($md) {
        lost-column: 1/4 4;
        &:nth-last-child(-n+4) {
          margin-bottom: 0px;
        }
      }


      &:hover {
        transform: scale(1.05);
      }
      .image__container {
        height: 230px;
        max-height: 230px;
        border-radius: 6px 6px 0px 0px; /* top left, top right, bottom right, bottom left */

      }

      .content__container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        .content {
          display: flex;
          flex-direction: column;
          height: 100%;
          .h3 {
            @include fluid-type(17px, 25px);
            margin-bottom: 16px;
          }

          .summary {

            p {
              line-height: 24px;
              @include fluid-type(12px, 15px);
              color: theme(gray);
            }
          }



        }
        .link {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          span {
            color: theme(ac-green);
            @include fluid-type(12px, 14px);
            font-weight: 600;
          }

          .icon {
            height: 10px;
            width: 5px;
          }
        }
      }
    }
  }

}
// Forms
// --------------------------------------------------

input,
label,
select {
  display: block;
}

label {
  margin-bottom: $half-spacing;
  font-family: $heading-font-family;
  font-weight: 500;

  &.required::after {
    content: "*";
  }
}

select[multiple=multiple],
textarea {
  background-color: $background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: none;
  box-sizing: border-box;
  font-family: $base-font-family;
  margin-bottom: $half-spacing;
  padding: 0.3em 0.7em 0.5em 0.7em;
  transition: border-color;
  width: 100%;

  &:hover {
    border-color: darken($border-color, 10%);
  }

  &:focus {
    border-color: $action-color;
    box-shadow: none;
    outline: none;
  }
}

textarea {
  resize: vertical;
}

input[type="search"] {
  appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-right: $half-spacing;
}

input[type="file"] {
  padding-bottom: $half-spacing;
  width: 100%;
}

select {
  margin-bottom: $base-spacing;
  max-width: 100%;
  width: auto;
}

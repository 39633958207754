// Buttons
// --------------------------------------------------

.btn {
  display: inline-block;
  padding: $half-spacing $base-spacing;
  color: $action-color;
  background-color: transparent;
  font-family: $base-font-family;
  font-weight: normal;
  font-size: 20px;
  border-radius: $base-border-radius;
  cursor: pointer;
  transition-property: color, background-color, border-color, width;
  transition: $base-duration $ease-out-quint;
  margin-bottom: 0;
  border: 3px solid transparent;
  margin-right: $half-spacing;
  margin-bottom: $half-spacing;
  line-height: 1.2;

  &:hover,
  &:focus {
    color: $action-color;
    outline: none;
  }

  &:active {
    color: $action-color;
  }

  &:last-child {
    margin-right: 0;
  }
}

// Sizes
.btn--small {
  padding: 0.5em 1em;
  display: inline-block;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  background-color: $brand-color;
  color: #fff;

  &:hover,
  &:focus {
    background-color: $brand-color;
    color: #fff;
  }

  &:active {
    background-color: $brand-color;
    color: #fff;
  }
}

.btn--large {
  padding: 1em 3em;
}

// Layout
.btn--block {
  width: 100%;
  display: block;
}

// Types
.btn--primary {
  background-color: $brand-color;
  color: #fff;

  &:hover,
  &:focus {
    background-color: $brand-color;
    color: #fff;
  }

  &:active {
    background-color: $brand-color;
    color: #fff;
  }
}

.btn--outline {
  border-color: $brand-color;
  background-color: transparent;
  color: $brand-color;
  border-width: 2px;
  display: block;
  margin-right: 0;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 0.25em 1em;
  &:hover,
  &:active,
  &:focus {
    border-color: $brand-color;
    background-color: $brand-color;
    color: #ffffff;
  }

}

.btn--link {
  padding-left: 0;
  padding-right: 0;
}

// States
.btn--disabled,
.btn[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

.btn--loading {
  pointer-events: none;
  text-indent: -9999rem;
}

.btn--icon {
  @include fluid-type(16px, 24px);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: $white;
    color: theme(ac-green);

    svg {
      path {
        stroke: theme(ac-green);
      }
    }
  }

  &:active {
    background-color: theme(ac-green);
    color: $white;

    svg {
      path {
        stroke: $white;
      }
    }
  }

  .icon {
    margin-right: 6px;
    width: 27px;
    height: 27px;
    align-content: center;
    justify-content: center;

    svg {
      width: 27px;
      height: 27px;
    }
  }
}

.btn--cta {
  @include fluid-type(14px, 14px);
  padding: 0.5em 1em;
  display: inline-block;
  line-height: 27px;
  text-align: center;
  font-weight: bold;
  background-color: $white;
  color: theme(dark-green);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: theme(ac-green);
    color: $white;

    svg {
      path {
        fill: $white;
      }
    }
  }

  &:active {
    background-color: $white;
    color: theme(ac-green);

    svg {
      path {
        fill: theme(ac-green);
      }
    }
  }


  .icon {
    margin-right: 6px;
    width: 17px;
    height: 17px;
    display: flex;
    align-content: center;
    justify-content: center;

    svg {
      width: 17px;
      height: 17px;
    }
  }
}

// Header
// --------------------------------------------------

header {
  height: 100vh;
  position: relative;
  .header__content {
    height: 100%;
    align-items: center;
  }

  .h1 {
    color: #ffffff;
    margin-bottom: 20px;
  }

  .summary {
    p {
      @include fluid-type(20px, 28px);
      color: #ffffff;
    }

  }

  .header__icon {
    lost-column: 1/1 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header__icon {
    position: absolute;
    bottom: 40px;
  }
}

.header__logo {
  display: block;
  height: 100%;
}
